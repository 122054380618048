@import "site.css";
@import "intro.css";
@import 'shop.css';

@font-face {
  font-family: 'FGC';
  src: url('../fonts/fgc.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
* {
  font-family: 'FGC', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: 0.05em;
}
/* Link styling */
a {
  color: #ffffff;
  text-decoration: underline;
}

a:visited {
  color: #808080;
  text-decoration: underline;
}

#welcome {
  padding: 40px;
  background: #000;
  div {
    font-size: 20px;
    letter-spacing: 1px;
    padding: 10px 0;
    color: #111;
    transition: color 0.25s ease-in-out, transform 1.5s ease-in-out;
    line-height: 1.5;
  }
  /* .active {
    color: #FFF;
  }
  .active-2 {
    color: #555;
    transform: translateY(-3px);
    transition: color 0.8s ease-in-out, transform 0.5s cubic-bezier(.33,1.05,.39,1.02);
  }
  .active-1 {
    color: #888;
    transform: translateY(-7px);
    transition: color 0.5s ease-in-out, transform 0.5s cubic-bezier(.33,1.05,.39,1.02);
  }
  .active-0 {
    color: #FFF;
    transform: translateY(-10px);
    transition: color 0.25s ease-in-out, transform 0.5s cubic-bezier(.33,1.05,.39,1.02);
  }
  .active--1 {
    color: #888;
    transform: translateY(-7px);
  }
  .active--2 {
    color: #555;
    transform: translateY(-3px);
    transition: color 0.8s ease-in-out, transform 0.5s cubic-bezier(.33,1.05,.39,1.02);
  } */
}
.debug, .debug2 {
  canvas {
    height: 100vh;
  }
}

.hero {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2;
}


.ticker-wrap {
  width: 100%;
  height: 60px;
  overflow: hidden;
  position: fixed;
  top: 0;
  background-color: #000000;
  z-index: 1000;
  opacity: 1 !important;
}

.ticker {
  display: inline-flex;
  height: 100%;
  a {
    color: #ffffff;
    font-size: inherit;
    text-decoration: underline;
  }
}

.ticker-content {
  display: inline-flex;
  white-space: nowrap;
  animation: ticker 30s linear infinite;
}

.ticker-content:nth-child(2) {
  animation: ticker2 30s linear infinite;
}

.ticker-item {
  padding: 20px 2rem;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem !important;
  color: white;
  * {
    font-size: inherit !important;
  }
}

.stock {
  display: flex;
  align-items: center;
  gap: 10px;
}
.stock-price {
  transition: color 0.3s ease;
}
.stock-price.up {
  color: #00ff00;
}
.stock-price.down {
  color: #00ff00;
}
.sparkline {
  height: 20px;
  width: 60px;
  vertical-align: middle;
}
@keyframes ticker {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker2 {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

.item-collection-1 {
  position: relative;
  left: 0%;
  animation: swap 40s linear infinite;
}

.item {
  display: inline-block;
  padding: 0 1rem;
  font-size: 1rem;
  color: black;
  font-weight: 500;
  text-transform: uppercase;
  font-family: sans-serif;
}

/* Transition */
@keyframes marquee {
  0% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(-100%)
  }
}

@keyframes swap {
  0%, 50% {
    left: 0%;
  }
  50.01%,
  100% {
    left: 100%;
  }
}

.reviews {
  background: #000000;
  padding: 6rem 2rem;
  color: #ffffff;
}

.reviews-header {
  text-align: center;
  margin-bottom: 4rem;
}

.reviews-header h2 {
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif; */
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.reviews-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3rem;
  max-width: 1400px;
  margin: 0 auto;
}

.review {
  background: #111111;
  padding: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.2s ease;
}

.review:hover {
  transform: translateY(-5px);
}

.review-quote {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  font-style: italic;
  color: #ffffff;
}

.review-source {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.review-logo {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  background: #222;
  object-fit: cover;
}

.review-meta {
  flex: 1;
}

.review-publication {
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 0.25rem;
}

.review-author {
  color: #666666;
  font-size: 0.9rem;
}

#mini-player {
  height: 100vh;
  background: #000000;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  margin-bottom: 2rem;
}

#mini-player > * {
  width: 100%;
  max-width: 1200px;
}

#mini-player h2 {
  color: #ffffff;
  text-align: left;
}

.live-cam-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  height: calc(100vh - 200px);
}

.live-cam-gif {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  margin: auto;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  background: rgba(0, 0, 0, 0.6);
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease, opacity 0.5s ease;
  z-index: 10;
  opacity: 1;
}

.play-button:hover {
  background: rgba(0, 0, 0, 0.8);
  transform: translate(-50%, -50%) scale(1.1);
  border-color: rgba(255, 255, 255, 0.5);
}

.play-button::before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 15px 25px;
  border-color: transparent transparent transparent rgba(255, 255, 255, 0.8);
  margin-left: 5px;
}

.play-button.playing::before {
  width: 20px;
  height: 20px;
  border: none;
  background: rgba(255, 255, 255, 0.8);
  margin: 0;
}

.play-button.playing {
  opacity: 0;
}

.live-cam-container:hover .play-button.playing {
  opacity: 1;
}

.now-playing {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 0;
  color: #ff3333;
  font-family: "VCR OSD Mono", "Courier New", monospace;
  font-size: 1rem;
  font-weight: bold;
  text-shadow: 1px 1px 0 rgba(0,0,0,0.8);
  z-index: 20;
  mix-blend-mode: screen;
  opacity: 0.85;
  letter-spacing: 1px;
  white-space: nowrap;
  pointer-events: none;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.now-playing.visible {
  opacity: 0.85;
}

.timestamp-overlay {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0;
  color: #ff3333;
  font-family: "VCR OSD Mono", "Courier New", monospace;
  font-size: 1rem;
  font-weight: bold;
  text-shadow: 1px 1px 0 rgba(0,0,0,0.8);
  z-index: 20;
  mix-blend-mode: screen;
  opacity: 0.85;
  letter-spacing: 1px;
  white-space: nowrap;
  pointer-events: none;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

/* only if browser is less than 850px wide */
@media (max-width: 850px) {
  .timestamp-overlay {
    top: 45px;
    right: inherit;
    left: 20px;
  }
}

.sender-form-field {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1001;
  &.hidden {
    display: none;
  }
  .close {
    position: absolute;
    top: 15px;
    right: -3px;
    padding: 0px;
    box-sizing: border-box;
    cursor: pointer;
    width: 40px;
    height: 40px;
    z-index: 1002;
    svg {
      width: 100%;
      height: 100%;

    }
  }
}

@media (max-width: 600px) {
  .sender-form-field {
    bottom: 10px;
    right: 10px;
    padding: 10px;
  }
}

#featured-product {
  background: #000000;
  padding: 2rem;
  width: 100%;
  box-sizing: border-box;
}

.product-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.featured-product-image {
  width: 100%;
  height: auto;
  max-width: 100%;
  display: block;
}

.product-details {
  color: #ffffff;
  padding: 4rem 2rem;
  text-align: left;
}

.product-details h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0;
  letter-spacing: 0.05em;
}

.product-tagline {
  color: #666666;
  font-size: 1.1rem;
  margin: 1rem 0 2rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.product-description {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #999999;
  margin-bottom: 2rem;
}

.product-meta {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.price-tag {
  font-size: 3rem;
  font-weight: 700;
  color: #ffffff;
  &.sold-out {
    text-decoration: line-through;
  }
}

.price-decimals {
  font-size: 1.5rem;
  opacity: 0.5;
}

.buy-button {
  display: inline-block;
  background: #ffffff;
  color: #000000;
  padding: 1rem 2rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.1em;
  transition: all 0.3s ease;
}

.buy-button:hover {
  background: #cccccc;
  transform: translateY(-2px);
}

.product-features {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.feature {
  color: #666666;
  font-size: 0.9rem;
  letter-spacing: 0.05em;
}

@media (max-width: 900px) {
  #featured-product {
    padding: 1rem;
  }

  .product-details {
    padding: 2rem 1rem;
  }
}

.newsletter-signup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1001;
  max-width: 300px;
  width: 90%;
}

.signup-cta {
  font-size: 0.3rem;
  font-weight: 400;
  letter-spacing: 0.06em;
  color: rgba(255, 255, 255, 0.2);
  margin-bottom: 4px;
  text-align: left;
  padding-left: 12px;
  transition: color 0.3s ease;
}

.newsletter-signup:hover .signup-cta {
  color: rgba(255, 255, 255, 0.4);
}

.signup-form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-content {
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.95);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  padding: 2px;
  transition: border-color 0.3s ease;
}

.form-content:hover,
.form-content:focus-within {
  border-color: rgba(255, 255, 255, 0.3);
}

.signup-form input {
  flex: 1;
  background: none;
  border: none;
  padding: 12px;
  color: #ffffff;
  font-size: 0.9rem;
  letter-spacing: 0.05em;
}

.signup-form input:focus {
  outline: none;
}

.signup-form input::placeholder {
  color: rgba(255, 255, 255, 0.3);
  letter-spacing: 0.05em;
}

.signup-form button {
  background: none;
  border: none;
  color: #ffffff;
  width: 40px;
  height: 40px;
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

.signup-form button:hover {
  transform: translateX(2px);
}

.signup-message {
  font-size: 0.7rem;
  letter-spacing: 0.1em;
  text-align: right;
  height: 15px;
  padding: 0 4px;
}

.signup-message.success {
  color: rgba(255, 255, 255, 0.6);
}

.signup-message.error {
  color: #ff3333;
}

@media (max-width: 600px) {
  .newsletter-signup {
    bottom: 10px;
    right: 10px;
  }
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.blink {
  animation: blink 1s infinite;
  display: inline-block;
}

/* Loading Overlay Styles */
#loading-overlay {
  position: fixed;
  top: 60px; /* Start below the ticker */
  left: 0;
  width: 100%;
  height: calc(100% - 60px); /* Subtract ticker height */
  background: #000;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease-out;
  opacity: 0; /* Start hidden */
}

#loading-overlay.visible {
  opacity: 1;
}

#loading-overlay.hidden {
  opacity: 0;
  pointer-events: none;
}

.loading-spinner {
  position: relative;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-30px); /* Offset half the ticker height to center in viewport */
}

.loading-spinner::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border: 4px solid #333;
  border-top-color: #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.spinner-text {
  position: relative; /* Changed from absolute */
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.1em;
  z-index: 1;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

#main-content {
  opacity: 0; /* Start hidden */
  transition: opacity 0.5s ease-out;
}

#main-content.loaded {
  opacity: 1;
}

/* Make sure ticker is always visible and above loading overlay */
.ticker-wrap {
  opacity: 1 !important;
  z-index: 10000 !important;
}

/* Hide canvas initially */
#canvas-webgl {
  opacity: 0;
  transition: opacity 1s ease-out;
}

#canvas-webgl.loaded {
  opacity: 1;
}

/* Add responsive adjustments */
@media (max-width: 768px) {
  .ticker-item {
    padding: 20px 1rem;
    font-size: 0.9rem !important;
    * {
      font-size: inherit !important;
    }
  }
}


#upcoming-shows {
  max-width: 768px;
  margin: 0 auto 40px;
  color: #888;
  text-align: center;
  img {
    max-width: 90vw;
  }
  p {
    font-size: 1.2rem;
    line-height: 1.5;
    span {
      color: #FFF;
    }
  }
}