#shop {
  margin-bottom: 6rem;
  padding-bottom: 3rem;
  background-color: #222222;
}

.shop-header {
  text-align: center;
  padding: 4rem 2rem 2rem;
  color: #ffffff;
}

.shop-header h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.shop-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  row-gap: 6rem;
  column-gap: 4rem;
  padding: 3rem;
  max-width: 1400px;
  margin: 0 auto;
}

.product {
  background: #000000;
  padding: 2rem;
  border-radius: 4px;
  text-align: center;
  transition: transform 0.2s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 2rem;
}

.product:hover {
  transform: translateY(-5px);
}

.product-image-container {
  width: 100%;
  height: 300px;
  overflow: hidden;
  border-radius: 2px;
  margin-bottom: 1rem;
  background: #111111;
  flex-shrink: 0;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 8s ease;
}

.product:hover .product-image {
  transform: scale(1.2) rotate(2deg);
}

.product-title {
  font-size: 1.4rem;
  margin: 1rem 0 0.5rem;
  color: #ffffff;
  font-weight: 600;
  flex-shrink: 0;
}

.product-description {
  font-size: 0.95rem;
  color: #999999;
  margin: 0.5rem 0 1rem;
  line-height: 1.4;
  flex: 1;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.product-price {
  font-size: 1.2rem;
  color: #ffffff;
  font-weight: 600;
  margin: 0.5rem 0;
  flex-shrink: 0;
}

.buy-now {
  display: inline-block;
  background: #ffffff;
  color: #000000;
  padding: 0.8rem 2rem;
  border-radius: 4px;
  text-decoration: none;

  font-weight: 600;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transition: all 0.2s ease;
  margin: 0.5rem 0;
  flex-shrink: 0;
}

.buy-now:hover {
  background: #cccccc;
  transform: translateY(-2px);
}

.sold-out {
  color: #ff3333;
  font-weight: 600;
  margin: 0.5rem 0;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 0.9rem;
  flex-shrink: 0;
}
